.figure {
    @extend .d-block;
    @extend .text-center;
    margin: 0 0 25px;
}

.figure__picture {
    @extend .d-block;
    margin: 0 0 10px;
    border: 5px solid #ebebeb
}

.figure__caption {
    @include font(16px,bold);
    color: #444;
    text-decoration: underline;
}