@mixin before {
    position: relative;

    &:before {
        content: '';
        position: absolute;
    }
}

@mixin mw($mw,$width:100%) {
    width: $width;
    max-width: $mw;
}

@mixin fw($fw) {
    flex: 0 0 $fw;
    max-width: $fw;
}

@mixin offset($margin,$padding) {
    margin: $margin;
    padding: $padding;
}

@mixin pos($pt,$pl,$pp:absolute) {
    top: $pt;
    left: $pl;
    position: $pp;
}

@mixin border($bw,$bc,$bs:solid) {
    border: {
        width: $bw;
        color: $bc;
        style: $bs;
    }
}

@mixin bg($bgs,$bgp:0 0) {
    background: {
        size: $bgs;
        position: $bgp;
        repeat: no-repeat;
        color: rgba(0,0,0,0);
    }
}

@mixin font($fs,$fw) {
    font: {
        size: $fs;
        weight: $fw;
    }
}

@mixin fs-lh($fs,$lh) {
    font-size: $fs;
    line-height: $lh;
}

@mixin inline-block($va:top) {
    display: inline-block;
    vertical-align: $va;
}

@mixin prefixes($property, $value){
	#{-webkit-}#{$property}: #{$value};
	   #{-moz-}#{$property}: #{$value};
	    #{-ms-}#{$property}: #{$value};
	     #{-o-}#{$property}: #{$value};
	           #{$property}: #{$value};
}

@mixin transition($value){
	@include prefixes('transition', $value);
}