body {
	font: $font-size_default $font-family_default;
	line-height: $line-height_default;
	color: $color__text_default;
    background: url(../img/bg/bg.jpg) no-repeat fixed center top rgba(0,0,0,0);
    background-size: cover;
}

a {
    @extend %links_res;

    &:hover,
    &:focus {
        @extend %links_res;
    }
}

button {
    outline: 0;
    cursor: pointer;

    &:hover,
    &:focus {
        outline: 0;
        cursor: pointer;
    }
}

.zi-2 {
    @extend %zi-2;
}

.btn {
    @extend %font-roboto;
    @extend .border-0;
    @extend .text-uppercase;
    @include inline-block;
    font-size: 18px;
    color: #fff;
    padding: 7px 10px 6px;
    border-radius: 3px;
    background-color: #333;
    text-shadow: 0 1px 0 rgba(0,0,0,.2);
}

.text-underline {
    text-decoration: underline;
}

/* contacts */
.contacts {
    @extend .list-unstyled;
}

.contacts__items {
    margin: 0 0 10px;
}

/* sidebar */
.sidebar {
	@include media-breakpoint-up(lg) {
		@include prefixes(position, sticky);
		top: 20px;
	}
}

.sidebar__banner {
	@extend .px-1;
	@extend .py-3;
	@extend .text-center;
	margin: 0 0 25px;
	background: #fce72f;
	background: -moz-linear-gradient(top,  #fce72f 0%, #f5dc03 100%);
	background: -webkit-linear-gradient(top,  #fce72f 0%,#f5dc03 100%);
	background: linear-gradient(to bottom,  #fce72f 0%,#f5dc03 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fce72f', endColorstr='#f5dc03',GradientType=0 );
}


.sidebar__title {
	@extend .mb-3;
	@extend .text-uppercase;
	@extend %font-roboto;
	@include fs-lh(20px,$line-height_content);
	color: #444;
}

/* gallery */
.gallery {
}

.gallery__title {
	@extend .mb-2;
	@include font(20px,bold);
}

.gallery__item {
	@extend .mr-2;
	@extend .mb-2;
	@include inline-block;
	border: 10px solid #ebebeb;
}