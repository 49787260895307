.menu {
    &.menu_bg {
        box-shadow: 0 2px 15px 0 rgba(0,0,0,.18) inset;
        background: url(../img/bg/submenu.png) repeat scroll 0 0 rgba(0,0,0,0);
    }
    
    &.menu_visible {
        .menu__btn {
            color: #000;

            &:before {
                @include prefixes(transform,rotate(45deg));
                @include prefixes(transform-origin,top left);
                top: 12px;
                box-shadow: none;
                background: #000;
            }

            &:after {
                @include prefixes(transform,rotate(-45deg));
                @include prefixes(transform-origin,top right);
                top: 12px;
                right: 16px;
                background: #000;
            }
        }

        .menu__list {
            @extend .d-block;
        }

        .menu__caption {  
            &.menu__caption_show {
                display: none;
            }
        
            &.menu__caption_hide {
                display: inline;
            }
        }
    }
}

.menu__btn {
    @extend .text-left;
    @extend .d-lg-none;
    @extend .d-block;
    @extend %font-roboto;
    @include before;
    @include mw(100%);
    @include border(0 1px,#ededed #c8c8c8 #c8c8c8 #ededed);
    padding: 15px 45px 15px 15px;
    color: #555;
    cursor: pointer;
    text-shadow: 1px 1px #fff;

    &:hover,
    &:focus {
        color: #000;
    }

    &:before {
        @include size(30px,3px);
        @include transition(all .3s ease 0s);
        top: 15px;
        right: 5px;
        border-radius: 3px;
        background: #555;
        box-shadow: 0 8px 0 0 #555;
    }

    &:after {
        content: '';
        @include size(30px,3px);
        @include transition(all .3s ease 0s);
        position: absolute;
        top: 31px;
        right: 5px;
        border-radius: 3px;
        background: #555;

    }
}

.menu__caption {  
    &.menu__caption_show {
        display: inline;
    }

    &.menu__caption_hide {
        display: none;
    }
}

.menu__list {
    @extend .d-lg-flex;
    @extend .d-none;
    @extend .flex-wrap;
    @extend .justify-content-center;
    @extend %font-roboto;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);

    &.menu__list_bg {
        @include border(1px,#ededed #c8c8c8 #c8c8c8 #ededed);

        @include media-breakpoint-up(lg) {
            padding: 0 5px;
            border-radius: 5px 5px 0 0;
            background: #ebebeb;
            background: -moz-linear-gradient(top,  #ebebeb 0%, #d1d1d1 100%);
            background: -webkit-linear-gradient(top,  #ebebeb 0%,#d1d1d1 100%);
            background: linear-gradient(to bottom,  #ebebeb 0%,#d1d1d1 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ebebeb', endColorstr='#d1d1d1',GradientType=0 );
        }

        .menu__item {
            @include media-breakpoint-down(md) {
                background: #ebebeb;
                background: -moz-linear-gradient(top,  #ebebeb 0%, #d1d1d1 100%);
                background: -webkit-linear-gradient(top,  #ebebeb 0%,#d1d1d1 100%);
                background: linear-gradient(to bottom,  #ebebeb 0%,#d1d1d1 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ebebeb', endColorstr='#d1d1d1',GradientType=0 );
            }
        }
    }
}

.menu__item {
    @extend .d-lg-inline-block;
    @extend .d-block;
    @extend .align-top;
    @include before;
    color: #555;
    text-shadow: 1px 1px #fff;

    @include media-breakpoint-up(xl) {
        font-size: 20px;
    }

    @include media-breakpoint-down(lg) {
        font-size: 16px;
    }

    @include media-breakpoint-up(lg) {
        @include border(0 1px 0 0,#ccc);
        padding: 15px 35px;
    }

    @include media-breakpoint-down(md) {
        @include border(0 0 1px,#ccc);
        padding: 15px;
    }

    &:last-child {
        @extend .border-0;

        &:before {
            @extend .d-none;
        }
    }

    &.current,
    &:hover,
    &:focus {
        color: #555;
        background: #d1d1d1 !important;
        background: -moz-linear-gradient(top,  #d1d1d1 0%, #ebebeb 100%) !important;
        background: -webkit-linear-gradient(top,  #d1d1d1 0%,#ebebeb 100%) !important;
        background: linear-gradient(to bottom,  #d1d1d1 0%,#ebebeb 100%) !important;
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d1d1d1', endColorstr='#ebebeb',GradientType=0 ) !important;     
    }

    &:before {
        @include media-breakpoint-up(lg) {
            top: 0;
            right: -2px;
            bottom: 0;
            width: 1px;
        }

        @include media-breakpoint-down(md) {
            left: 0;
            right: 0;
            bottom: -2px;
            height: 1px;
        }

        background: rgba(255, 255, 255, 0.56);
    }

    &.menu__item_sm {
        padding-left: 15px;
        padding-right: 15px;
    }
}