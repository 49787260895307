.selecter {
    @include mw(320px);
    position: relative;

    &.selecter_open {
        box-shadow: 0 1px 10px 0 rgba(0,0,0,.1);

        .selecter__title {
            box-shadow: none;
            border-radius: 3px 3px 0 0;

            &:before {
                @include border(0 6px 8px,rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,.18));
            }
        }

        .selecter__list {
            display: block;
        }
    }
}

.selecter__title {
    @extend .text-left;
    @include before;
    @include mw(100%);
    @include border(1px,rgba(0,0,0,.18));
    padding: 5px 25px 5px 5px;
    display: block;
    cursor: pointer;
    border-radius: 3px;
    background: #fff;
    box-shadow: 1px 1px 0 0 #fff;

    &:before {
        @include border(8px 6px 0,rgba(0,0,0,.18) rgba(0,0,0,0) rgba(0,0,0,0));
        top: 10px;
        right: 6px;
    }
}

.selecter__list {
    @include border(0 1px 1px,rgba(0,0,0,.18));
    position: absolute;
    left: 0;
    right: 0;
    display: none;
    border-radius: 0 0 3px 3px;
    background: #fff;
}

.selecter__item {
    @include border(0 0 1px,rgba(0,0,0,.18));
    padding: 5px;
    display: block;

    &:last-child {
        @extend .border-0;
    }

    &:hover,
    &:focus {
        color: #000;
        background: rgba(0,0,0,.05);
    }
}