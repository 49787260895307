%links_res {
    text-decoration: none;
    color: $color__text_links;
}

%text_content {
    font-size: $font-size_default;
    line-height: $line-height_content;
}

%zi-2 {
    z-index: 2;
    position: relative;
}

%font-roboto {
    font-family: $font-family_logo;
    font-weight: 500;
}