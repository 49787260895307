.header {
    @extend %zi-2;
    padding: 15px 0 20px;
    box-shadow: 0 2px 15px 0 rgba(0,0,0,.18);
    background: url(../img/bg/header.png) repeat scroll 0 0 rgba(0,0,0,0);

    @include media-breakpoint-up(lg) {
        margin: 0 0 35px;
    }
}

.header__logo {
    @extend %font-roboto;
    @extend .text-uppercase;
    @include inline-block;
    @include font(30px,500);
    background-image: url(../img/icons/icon-logo.png);

    @include media-breakpoint-up(lg) {
        @include bg(auto,center top);
        padding: 45px 0 0;
    }

    @include media-breakpoint-down(mg) {
        @include bg(auto 100%,left center);
        padding: 0 0 0 60px;
    }
}

.header__phone {
    @extend %font-roboto;
    @include font(35px,500);
    text-shadow: 1px 1px #fff;
}

.header__phone-prefix {
    @include inline-block(middle);
    font-size: 20px;
}