.promo {
    @include before;
    color: #fff;
    padding: 40px 0;
    background: url(../img/bg/promo.jpg) no-repeat scroll center top rgba(0,0,0,0);
    background-size: cover;

    &:before {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        background: rgba(0,0,0,.25);
    }
}

.promo__container {
	@include media-breakpoint-down(sm) {
		@include mw(100%);
	}
}

.promo__title {
    @extend .text-center;
    @extend .text-uppercase;
    @extend %font-roboto;
    margin: 0 0 55px;
    text-shadow: 2px 1px rgba(0,0,0,.53);

    @include media-breakpoint-up(xl) {
        font-size: 60px;
    }

    @include media-breakpoint-down(lg) {
        font-size: 56px;
    }
}

.promo__list {
    @extend .text-center;
    @extend %font-roboto;
}

.promo__feature {
    @include before;
    padding: 145px 0 0;
    font-size: 35px;
    text-shadow: 2px 1px rgba(0,0,0,.53);

    &:before {
        @extend .d-flex;
        @extend .flex-wrap;
        @extend .align-items-center;
        @extend .justify-content-center;
        @include pos(0,50%);
        @include size(124px,124px);
        margin: 0 0 0 -62px;
        border-radius: 50%;
        border: 13px solid #fff;
        text-shadow: 2px 1px rgba(0,0,0,.53);
        box-shadow: 2px 1px 0 0 rgba(0,0,0,.53);
    }

    &.promo__feature_ophours {
        &:before {
            @include bg(100% 100%);
            border: 0;
            box-shadow: none;
            text-shadow: none;
            background-image: url(../img/icons/icon-ophours.png);
        }
    }

    &.promo__feature_week {
        &:before {
            content: '7';
            font-size: 100px;
        }
    }

    &.promo__feature_year {
        &:before {
            content: '365';
            font-size: 50px;
        }
    }
}