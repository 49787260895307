@import '../../node_modules/swiper/swiper';
@import '../../node_modules/lightbox2/src/css/lightbox.css';

@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/grid";
/*
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/input-group";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/list-group";
*/
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/utilities";

@import 'variables';
@import 'mixins';
@import 'extends';
@import 'scaffoldings';

@import 'header';
@import 'menu';
@import 'form';
@import 'promo';
@import 'selecter';
@import 'content';
@import 'figure';
@import 'slides';
@import 'product';
@import 'footer';