.product {
	
}

.product__img {
	@include inline-block;
	border: 10px solid #ebebeb;
}

.product__title {
	@include fs-lh(14px,16px);
	text-shadow: 1px 1px rgba(255,255,255,.9);
	
	&.product__title_thin {
		line-height: $line-height_content;
		
		&:before {
			@extend .mr-2;
			content: '»';
		}
	}
	
	&.product__title_bold {
		@extend .mb-2;
		@include font(16px,bold);
		line-height: $line-height_content;
	}
}

.product__params {
	padding: 15px;
	background: rgba(235,235,235,.35);
	box-shadow: 1px 1px 10px 0 rgba(0,0,0,.05) inset;
}

.product__param {
	@extend .mb-3;
	@include fs-lh(14px,16px);
	text-shadow: 1px 1px rgba(255,255,255,.9);
		
	&:before {
		@extend .mr-2;
		content: '»';
	}
	
	&.product__param_bold {
		@extend %font-roboto;

		&:before {
			@extend .d-none;
		}
	}
}

.product__footer {
}

.product__btn {
	@include inline-block;
	@include fs-lh(16px,$line-height_content);
    padding: 3px 5px;
	background: #fff;
    border: 1px solid #ebebeb;

	&.product__btn_active {
		color: #fff;
		background: #d23139;
	}

	&.product__btn_order {
		@extend %font-roboto;
		@extend .border-0;
		@extend .text-uppercase;
		font-size: 18px;
		color: #fff;
		padding: 3px 5px;
		background: #333;
		border-radius: 3px;
		text-shadow: 0 1px 0 rgba(0,0,0,.2);
	}
}