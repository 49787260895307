.content {
    p {
        @extend %text_content;
        margin: 0 0 10px;
    }

    li {
        @extend %text_content;
        margin: 0 0 10px;
    }

    a {
        text-decoration: underline;
    }

    h1 {
        @extend .font-weight-bold;
		
		@include media-breakpoint-up(md) {
			font-size: 36px;
			margin: 0 0 20px;
		}
		
		@include media-breakpoint-down(sm) {
			font-size: 32px;
			margin: 0 0 16px;
		}
    }

    h2 {
        @extend .font-weight-bold;
		
		@include media-breakpoint-up(md) {
			font-size: 32px;
			margin: 0 0 16px;
		}
		
		@include media-breakpoint-down(sm) {
			font-size: 28px;
			margin: 0 0 12px;
		}
    }

    h3 {
        @extend .font-weight-bold;
		
		@include media-breakpoint-up(md) {
			font-size: 28px;
			margin: 0 0 12px;
		}
		
		@include media-breakpoint-down(sm) {
			font-size: 22px;
			margin: 0 0 10px;
		}
    }
}