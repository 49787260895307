.form {
    &.form_colored {
        @extend .d-lg-block;
        @extend .d-sm-flex;
        @extend .flex-wrap;
        border-radius: 3px;
        border: 1px solid #edd922;
        color: #444;
        background: #fce72f;
        background: -moz-linear-gradient(top,  #fce72f 0%, #f5dc03 100%);
        background: -webkit-linear-gradient(top,  #fce72f 0%,#f5dc03 100%);
        background: linear-gradient(to bottom,  #fce72f 0%,#f5dc03 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fce72f', endColorstr='#f5dc03',GradientType=0 );        
        box-shadow: 0 1px 1px rgba(0,0,0,.2);

		@include media-breakpoint-up(md) {
			padding: 25px 20px;
		}

		.form__field {
			border-radius: 3px;
			border: 2px solid #f1c823;
			box-shadow: 0 2px 10px rgba(0,0,0,.11)inset;
		}
		
		.form__label_checked {
			&:before {
				border-radius: 3px;
				border: 2px solid #f1c823;
				box-shadow: 0 2px 10px rgba(0,0,0,.11)inset;
			}
		}

        @include media-breakpoint-down(md) {
            .form__title {
                @include fw(100%);
            }

            .form__footer {
                @include fw(100%);
            }
        }
		
		@include media-breakpoint-between(sm, md) {
            .form__item {
                @include fw(50%);

                &:nth-of-type(2n) {
                    padding: 0 15px 0 0;
                }
            }
		}

		@include media-breakpoint-down(sm) {
			padding: 20px 15px;
		}
    }
	
	&.form_bordered {
		@extend .pb-4;
		@extend .pt-3;
		@extend .px-sm-4;
		@extend .px-3;
		border-radius: 5px;
		border: 5px solid #333;
    }
}

.form__title {
    @extend %font-roboto;
    @extend .text-center;
    @extend .text-uppercase;
    color: #444;
    font-size: 20px;
    margin: 0 0 10px;
	position: relative;
	
	&.form__title_lg {
		font-size: 26px;
	}
}

.form__close {
	position: absolute;
	top: -5px;
	right: 0;
}

.form__item {
    margin: 0 0 10px;

    &.form__item_flex {
        @extend .d-lg-flex;
        @extend .flex-wrap;
        @extend .align-items-center;

        .form__label {
            @extend .mb-lg-0;
            @extend .pr-lg-3;

            @include media-breakpoint-up(lg) {
                @include fw(100%/3);
            }
        }
    }
}

.form__label {
    @extend .d-block;
    font-size: 15px;
    margin: 0 0 3px;
    text-shadow: 0 1px 0 rgba(255,255,255,.33);

    &.form__label_required {
        &:after {
            content: '*';
            color: #f00;
            font-size: 16px;
            margin: 0 0 0 3px;
        }
    }

    &.form__label_checked {
        @include before;
        cursor: pointer;
        padding: 0 0 0 30px;

        &:before {
            @include pos(-2px,0);
            @include size(20px,20px);
            border: 1px solid #ccc;
            background: #fff;
        }

        &:after {
            content: '';
            @extend .d-none;
            @include pos(-2px,0);
            @include bg(80% 80%,center center);
            @include size(20px,20px);
            background-image: url(../img/icons/icon-checked.svg);
        }
    }
}

.form__field {
    width: 100%;
    padding: 4px 7px;
	border: 1px solid #ccc;

    &.form__field_right {
        @include media-breakpoint-up(lg) {
            @include fw(100%/3*2);
        }
    }
}

.form__checkbox {
    @extend .d-none;

    &:checked {
        & + .form__label_checked {
            &:after {
                @extend .d-block;
            }
        }
    }
}

.form__col {
	@extend .mr-1;
	font-size: 14px;
	min-height: 62px;
    padding: 10px 5px;

	&.form__col_title {
		@extend .mb-1;
		@extend .font-weight-bold;
		background: #d4d4d4;
		
		@include media-breakpoint-only(xs) {
			min-height: auto;
		}
	}

	&.form__col_data {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		background: #f0f0f0;
	}
}

.form__footer {
    @extend .text-center;
}